// VALIDATION
$('form').on('submit', function (e) {
    const req = $(this).find('*').filter('[required]');
    req.each(function () {
        if ($(this).val() === '') {
            e.preventDefault();
            const alert = $(this).parents('div.form-group').find('div.text-danger');
            alert.removeClass('hidden');
            $(this).on('keyup', function () {
                if ($(this).val() !== '') {
                    alert.addClass('hidden');
                } else {
                    alert.removeClass('hidden');
                }
            });
        }
    });
});

// LOGIN FOCUS
$('#userid').focus();
